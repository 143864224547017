import axios from "axios";
import authHeader from "./auth-header";
import { config } from "../config";
import AuthService from "./auth.service";
class UserService {
  getUserInfo() {
    return axios
      .get(`${config["apiUrl"]}myProfile`, { headers: authHeader() })
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  changeDayStatus(userId, day, dayStatus) {
    return axios
      .put(
        `${config["apiUrl"]}user/${userId}/day/${day}/lock/${dayStatus}`,
        null,
        { headers: authHeader() }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getMyUsers() {
    return axios
      .get(`${config["apiUrl"]}user/`, { headers: authHeader() })
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getOpenDays(userId, year) {
    return axios
      .get(`${config["apiUrl"]}countOpenDays/user/${userId}/year/${year}`, {
        headers: authHeader()
      })
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getUserAllInfo(userId) {
    return axios
      .get(`${config["apiUrl"]}profile/user/${userId}`, {
        headers: authHeader()
      })
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  updateUserAllInfo(userId, bodyParms) {
    let headers = authHeader();
    headers["Content-Type"] = "multipart/form-data";

    return axios
      .post(`${config["apiUrl"]}profile/user/${userId}`, bodyParms, {
        headers: headers
      })
      .then(response => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getAllUserAllInfo() {
    return axios
      .get(`${config["apiUrl"]}profile/allUsers`, { headers: authHeader() })
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  activesUsers() {
    return axios
      .get(`${config["apiUrl"]}userActives`, { headers: authHeader() })
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getUserIntegration(userId) {
    return axios
      .get(`${config["apiUrl"]}userIntegration/user/${userId}`, { headers: authHeader() })
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  createUserIntegration(userId, bodyParms) {
    return axios
      .post(`${config["apiUrl"]}userIntegration/user/${userId}`, bodyParms, {
        headers:  authHeader()
      })
      .then(response => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  updateUserIntegration(id, bodyParms) {
    return axios
      .put(`${config["apiUrl"]}userIntegration/${id}`, bodyParms, {
        headers:  authHeader()
      })
      .then(response => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getAllUsers() {
    return axios
      .get(`${config["apiUrl"]}/allUsers`, { headers: authHeader() })
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getUserAcls() {
    return axios
      .get(`${config["apiUrl"]}aclsInfo`, { headers: authHeader() })
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getMyVehicles() {
    return axios
      .get(`${config["apiUrl"]}myVehicles`, { headers: authHeader() })
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}

export default new UserService();
