var user = JSON.parse(localStorage.getItem("user"));
var Acls = (user && user.moreInfo != undefined) ? JSON.parse(window.atob(user.moreInfo)) : [];

export default  {
  // data () {
  //   return {
  //     user: JSON.parse(localStorage.getItem("user")),
  //     acls: [],
  //   };
  // },
  created: function () {
    if (Acls.length == 0) {
      user = JSON.parse(localStorage.getItem("user"));
      Acls = (user && user.moreInfo != undefined) ? JSON.parse(window.atob(user.moreInfo)) : [];
    }
  },
  methods: {
    can:  function(alias) {
      return Acls.find((acl) => acl === alias);
    },
  },
};
