<template>
  <div
    class="layout-menu-container"
    v-if="this.$store.state.auth.status.loggedIn"
  >
    <AppSubmenu
      :items="model"
      class="layout-menu"
      :root="true"
      @menuitem-click="onMenuItemClick"
    />
  </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu";

export default {
  props: {
    model: Array,
  },
  methods: {
    onMenuItemClick(event) {
      this.$emit("menuitem-click", event);
    },
  },
  components: {
    AppSubmenu: AppSubmenu,
  },
};
</script>

<style scoped></style>
