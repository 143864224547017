import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class MessageService {
  countUnread(userId) {
    return axios
      .get(`${config["apiUrl"]}message/user/${userId}/countUnread`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getMessages(userId) {
    return axios
      .get(`${config["apiUrl"]}message/user/${userId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  changeReadStatus(messageId, bodyParams) {
    return axios
      .patch(`${config["apiUrl"]}message/${messageId}`, bodyParams, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  sendMessage(bodyParams) {
    let headers = authHeader();
    headers["Content-Type"] = "multipart/form-data";
    return axios
      .post(`${config["apiUrl"]}message`, bodyParams, {
        headers: headers,
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getConversation(messageId) {
    return axios
      .get(`${config["apiUrl"]}message/${messageId}/conversation`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getOutMessages(userId) {
    return axios
      .get(`${config["apiUrl"]}message/user/${userId}/outBox`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getAttachement(messageId, attachmentId) {
    return axios
      .get(`${config["apiUrl"]}message/${messageId}/attachment/${attachmentId}`, {
        headers: authHeader(),
        responseType: 'arraybuffer'
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}
export default new MessageService();
