<template>
  <div class="layout-profile" v-if="this.$store.state.auth.status.loggedIn">
    <div>
      <img src="assets/layout/images/profile.png" alt="" />
    </div>
    <button class="p-link layout-profile-link" @click="onClick">
      <span class="username">{{ currentUser.username }}</span>
      <i class="pi pi-fw pi-cog"></i>
    </button>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <li>
          <button class="p-link" @click="showProfile()">
            <i class="pi pi-fw pi-user"></i><span>Perfil</span>
          </button>
        </li>
        <li>
          <button class="p-link" @click="showCompetencyProfile()">
            <i class="pi pi-fw pi-star"></i><span>As minhas Competências</span>
          </button>
        </li>
        <li>
          <button class="p-link" @click="logout()">
            <i class="pi pi-fw pi-power-off"></i><span>Logout</span>
          </button>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    currentUser() {
      if (this.$store.state.auth.status.loggedIn) {
        return this.$store.state.auth.user;
      }
      return null;
    },
  },
  methods: {
    onClick(event) {
      this.expanded = !this.expanded;
      event.preventDefault();
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    showProfile() {
      this.$router.push("profile").catch(()=>{});
    },
    showCompetencyProfile() {
      this.$router.push("competency-profile-user").catch(()=>{});
    },
  },
};
</script>

<style scoped></style>
