<template>
  <div
    v-bind:class="[
      { 'no-margin-left': !this.$store.state.auth.status.loggedIn },
      'layout-footer',
      'p-text-right',
      'p-text-bold',
    ]"
  >
    <span class="footer-text p-mr-2">W4M Digital Solutions</span>
    <img src="./assets/logo.png" alt="w4m" width="50" />
  </div>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
.no-margin-left {
  margin-left: 0px !important;
}
</style>
