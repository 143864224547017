<template>
  <div class="p-d-flex p-jc-center">
    <Toast />
    <div class="card card-container">
      <img
        id="profile-img"
        src="../assets/icon_w4m.png"
        class="profile-img-card"
      />
      <form name="form" @submit.prevent="handleLogin">
        <div class="p-field p-col-12 p-md-12">
          <span class="p-float-label">
            <InputText
              id="username"
              v-model="user.username"
              v-validate="'required'"
              type="text"
              v-bind:class="[
                { 'p-error': errors.has('username') },
                'form-control'
              ]"
              name="username"
              v-on:keyup.enter="handleLogin()"
            />
            <label class="force-top-0" for="inputtext">Username</label>
          </span>
          <small v-if="errors.has('username')" class="p-error" role="alert">
            Username é obrigatório
          </small>
        </div>

        <div class="p-field p-col-12 p-md-12">
          <span class="p-float-label">
            <InputText
              id="password"
              v-model="user.password"
              v-validate="'required'"
              type="password"
              v-bind:class="[
                { 'p-error': errors.has('password') },
                'form-control'
              ]"
              name="password"
              v-on:keyup.enter="handleLogin()"
            />
            <label class="force-top-0" for="inputtext">Password</label>
          </span>
          <small v-if="errors.has('password')" class="p-error" role="alert">
            Password é obrigatório
          </small>
        </div>

        <div class="p-col-12 p-d-flex p-jc-center">
          <Button label="Login" @click="handleLogin()" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import User from "../models/user";

export default {
  name: "Login",
  data() {
    return {
      user: new User("", ""),
      loading: false,
      message: ""
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    handleLogin() {
      this.$toast.removeAllGroups();
      this.loading = true;
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch("auth/login", this.user).then(
            () => {
              this.$router.push("/");
            },
            error => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();

              this.$toast.add({
                severity: "error",
                summary: "Erro no Login",
                detail: this.message
              });
            }
          );
        }
      });
    }
  }
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.force-top-0 {
  margin-top: -6px !important;
}
</style>
